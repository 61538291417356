<!-- 我的主页架子 -->
<template>
    <div class="help">
        <topnav active="help" @help="get"></topnav>
        <topmenu active="user"></topmenu>

        <div class="help_wrap public_width d-flex">
            <ul class="help_left">
                <li v-for="(v,k) in menu" :key="k">
                    <p class="menu_title">{{v.name}}</p>
                    <ul class="son">
                        <li :class="open == item.id ? 'active': ''" class="son_title" v-for="(item,index) in v.children" :key="index" @click="change_name(item)">
                        {{item.name}}</li>
                    </ul>
                </li>
            </ul>

            <div class="help_right">
                <div v-if="open == 1" class="">
                    <div class="help_title">购物流程</div>
                    <p class="help_step"><span>第一步：</span>打开网站首页https://chip.ebaina.com，在搜索框中输入元器件型号、描述、参数进行搜索。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%209.png" />

                    <p class="help_step"><span>第二步：</span>进入详情页，找到您所需要的商品型号，点击右侧操作“填写数量，加入购物车”。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2010.png" />

                    <p class="help_step"><span>第三步：</span>将商品加入购物车成功后，可直接选择去购物车结算，或者继续采购。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2011.png" />

                    <p class="help_step"><span>第四步：</span>进入‘我的购物车’，确认商品信息，将订单进行结算。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2019.png" />

                    <p class="help_step"><span>第五步：</span>选择配送方式，填写正确详细的配送地址，以便配送人员及时送到您的手中。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2020.png" />
                    
                    <p class="help_step"><span>第六步：</span>选择发票信息；温馨提示：百纳芯城提供的发票种类有：电子发票，增值税普通发票（不可抵扣）和 增值税专用发票（可抵扣）三种。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%E6%96%B0%E7%9A%84.png" />

                    <p class="help_step"><span>第七步：</span>选择是否使用优惠券及E币抵扣；说明：E币为易百纳技术社区的一种虚拟货币，可通过购买或者做任务兑换获取，该货币可用于抵扣现金。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2028.png" />

                    <p class="help_step"><span>第八步：</span>选择支付方式，支付宝和微信可以直接扫码付款。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2026.png" />

                    <p class="help_step"><span>第九步：</span>付款完成后，页面会提示订单号及订单状态。</p>
                    <img class="help_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%AD%A5%E9%AA%A4%E5%9B%BE2/%E5%9B%BE%E5%83%8F%2027.png" />

                </div>
                <div v-if="open == 2" class="">
                    <div class="help_title">发货与签收</div>
                    <div class="deliver_title">产品发货</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">1.若您在当天16:00之前完成付款，我们承诺当天发货，超过16:00的订单则会在第二天安排进行发放。</p>
                        <p class="deliver_txt">2.商城有实时的订单处理进度查询，具体您可以查看百纳芯城网站会员中心。</p>
                    </div>
                    
                    <div class="deliver_title">产品签收</div>
                    <p class="deliver_txt deliver_box">快递员送货上门时，请您务必当面拆开外包装，进行货品检查，查看商品有没有明显的变形，破损。如有问题，请不要签收，并联系我商城客服人员。</p>
                    <div class="deliver_title">特别提醒</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">1.如果您的订单使用优惠券进行支付，退货商品的金额将以实际支付金额为准。</p>
                        <p class="deliver_txt">2.如果您的订单中包含赠品，您在退住商品的同时，需要将赠品一起退回。</p>
                    </div>
                </div>
                <div v-if="open == 3" class="">
                    <div class="help_title">退换货说明</div>
                    <div class="deliver_title">百纳芯城产品退换货规则，规定详情如下：</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">1.百纳芯城自营产品退换货，必须保证产品包装外观的完整（不能撕毁、粘黏异物），不影响产品的二次销售，否则将不能享受退/换货的服务。</p>
                        <p class="deliver_txt">2.百纳芯城的“货物标签”和“出库单”是我们退换货的凭证，退换货需同时出具，丢失和损毁均不接受退换货。</p>
                        <p class="deliver_txt">3.因质量问题退/换货，我们收到退回货的3-7个工作日内为您安排更换商品或将相应的购物款退回到您指定的帐户上。</p>
                        <p class="deliver_txt">4.如果不是因为产品的质量问题，您需要承担来回的邮费。 </p>
                        <p class="deliver_txt">5.退/换货地址：</p>
                        <p class="deliver_txt">南京市江宁区菲尼克斯路70号总部基地22栋108室； </p>
                        <p class="deliver_txt">退换货联系电话：18651648139</p>
                        <p class="deliver_txt">请注明订单号、联系人、联系方式及退/换货原因！</p>
                        <p class="deliver_txt">我们收到退货后会及时与您联系，完成换货及退款。 </p>
                        <p class="deliver_txt">邮编：210000。</p>
                    </div>
                </div>
                <div v-if="open == 4" class="">
                    <div class="help_title">配送方式</div>
                    <div class="deliver_title">配送方式</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">百纳芯城有合作的快递公司有：顺丰快递。</p>
                        <p class="deliver_txt">目前百纳芯城默认配送方式为快递送货上门，对于快递不能送达的地区，我们会转成邮政包裹寄送。为确保及时正确的将产品送达您手中，请在订购时填写详细的收货地址、联系人及联系方式。</p>
                    </div>
                </div>
                <div v-if="open == 5" class="">
                    <div class="help_title">使用协议</div>
                    
                    <div class="deliver_box">
                        <p class="deliver_txt">本协议详述您在<a class="blue" href="https://chip.ebaina.com">https://chip.ebaina.com</a>（以下简称”百纳芯城”）使用我们的服务所须遵守的条款和条件。如您有任何疑问，请及时联系我们。您注册成为百纳芯城用户前，必须仔细阅读本用户协议和隐私声明，一旦您注册成为了百纳芯城用户即表示您已经阅读、同意并接受本用户协议和隐私声明中所含的所有条款和条件。如果我们对本声明进行了任何变更，我们会在百纳芯城网站页面上发布有关变更事项的通知，所有协议内容在发布之日后自动生效。如您不同意该修订，您必须终止您与百纳芯城的用户关系。</p>
                    </div>

                    <div class="deliver_title">一、用户注册</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">（1） 用户资格：百纳芯城用户必须是适用法律下能够签订具有法律约束力的合同的个人。在不限制前述规定的前提下，我们的服务不向16周岁以下或被临时或无限期中止的百纳芯城用户提供。如您不合资格，请不要使用我们的服务。您的百纳芯城帐户不得向其他方转让或出售。百纳芯城保留根据其意愿中止或终止您的帐户的权利。</p>
                        <p class="deliver_txt">（2） 资料填写：为确保交易的正常进行，用户必须向百纳芯城供真实、有效的个人资料，如个人资料有任何变动，必须及时通知百纳芯城更新。百纳芯城用户同意接收来自百纳芯城或者百纳芯城合作伙伴发出的邮件、信息。</p>
                    </div>

                    <div class="deliver_title">二、 隐私</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">百纳芯城设有适用于所有用户并纳入用户协议的隐私声明。您应当在注册时阅读并接受隐私声明以使用百纳芯城网站，且您在作为百纳芯城用户期间将继续受其规定（及百纳芯城对隐私声明作出的任何修订）的约束。</p>
                        <p class="deliver_txt">（1）个人数据资料。包括个人识别资料：如姓名、电话、通信地址、住址、电子邮件地址等情况；个人背景：行业、岗位、工作单位等。在未经您同意及确认之前，百纳芯城不会将您的资料用于其它目的。</p>
                        <p class="deliver_txt">（2）使用非个人信息。百纳芯城将通过您的IP地址来收集非个人化的信息，包括您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，我们亦进行客流量统计，从而改进网站的管理和服务。</p>
                        <p class="deliver_txt">（3）个人数据安全。百纳芯城采用安全性的服务器使用控制设备保护保护您的个人数据。</p>
                        <p class="deliver_txt">（4）个人数据披露和限制利用。当国家机构依照法定程序要求百纳芯城披露个人资料时，百纳芯城将根据执法单位的要求或为公共安全目的提供个人资料。在此情况下披露的任何内容，百纳芯城不承担任何责任。百纳芯城在符合下列条件时，对个人资料进行必要范围以外利用：已取得您的书面同意；为免除您在生命、身体或财产方面之急迫危险；为防止他人权益之重大危害；为增进公共利益，且无害于您的重大利益。</p>
                        
                    </div>

                    <div class="deliver_title">三、交易</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">用户在百纳芯城进行物品购买时必须遵守以下条款：</p>
                        <p class="deliver_txt">（1）您应就您使用百纳芯城的服务及对物品购买遵守所有适用的中国法律、法规、条例和地方性法律要求。您还必须确保您遵守本用户协议和隐私声明及纳入上述文件的所有其它条款和规则的所有规定。</p>
                        <p class="deliver_txt">（2）如您在百纳芯城购买物品，您有义务完成与百纳芯城的交易，但法律或本用户协议禁止的交易除外。通过对一项物品的下订单，您同意受该物品描述所含的出售条件的约束，只要该等出售条件不违反本协议或不是非法的。</p>
                        <p class="deliver_txt">（3）百纳芯城网站上的广告，价目表和声明并不构成要约。百纳芯城有权在发现了百纳芯城网站上显现的产品及订单的明显错误或缺货的情况下，单方面撤回该信息或撤销合同。百纳芯城保留对产品订购的数量的限制权。在下订单的同时，您也同时承认了您已经达到购买这些产品的法定年龄，并将对您在订单中提供的所有信息的真实性负责。</p>
                        <p class="deliver_txt">（4）产品价格和可获性都在网站上指明。这类信息将随时更改且不发任何通知。送货费将另外结算，费用根据您选择的送货方式的不同而异。如果发生了意外情况，在确认了您的订单后，由于供应商提价，税额变化引起的价格变化，或是由于网站的错误等造成价格变化，百纳芯城会通过email或电话通知您，在百纳芯城没有取消订单的情况下，让您决定是否取消订单。</p>
                    </div>

                    <div class="deliver_title">四、售后服务与产品质量保证</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">（1）如您对提供服务、所购产品等有任何疑问，您可通过平台所载明的售后服务联系方式进行咨询，详见售后服务。</p>
                        <p class="deliver_txt">（2）产品的质量保障：百纳芯城提供的产品质量按以下标准执行：有国家标准或专业标准的，按国家标准或专业标准执行；无前述标准的，按生产企业标准执行；无生产企业标准的，由客户与百纳芯城协商确定。若经权威（或双方认可的）第三方检测机构检测认定确系百纳芯城原因导致的产品质量不符合以上标准，请您第一时间与售后客服联系，售后客服将竭诚为您服务。</p>
                    </div>

                    <div class="deliver_title">五、网站安全</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">您同意，未经百纳芯城明示书面准许，您不会为了任何目的使用任何机器人、蜘蛛软件、刷屏软件或其他自动方式进入网站。此外，您同意您将不会：</p>
                        <p class="deliver_txt">（1）进行任何对百纳芯城网站造成或可能造成（按百纳芯城自行酌情确定）不合理或不合比例的重大负荷的行为；</p>
                        <p class="deliver_txt">（2）未经百纳芯城事先明示书面准许，对网站的任何内容（除您的个人信息以外）制作拷贝、进行复制、修改、制作衍生作品、分发或公开展示；</p>
                        <p class="deliver_txt">（3）干扰或试图干扰网站的正常工作或网站上进行的任何活动；</p>
                        <p class="deliver_txt">（4）在网站中使用违反适用的法律法规下可能视为被禁止或可能被禁止的任何内容；</p>
                        <p class="deliver_txt">（5）对网站使用包含可能破坏、改变、删除、不利影响、秘密截取、未经授权而接触或征用任何系统、数据或个人资料的任何病毒、特洛依木马、蠕虫、定时炸弹、删除蝇、复活节彩蛋、间谍软件或其他电脑程序。</p>
                    </div>

                    <div class="deliver_title">六、知识产权声明</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">百纳芯城所提供的网络服务的相关著作权、专利权、商标、商业秘密及其它任何所有权或权利，均属百纳芯城所有。非经百纳芯城的同意，任何人或用户均不得擅自下载、复制、传输、改编、编辑，否则应负所有法律责任。百纳芯城拥有向用户提供网络服务过程中所产生并储存于服务器中的任何数据信息的所有权。</p>
                    </div>

                    <div class="deliver_title">七、免责声明</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">百纳芯城有义务在技术上确保网站的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交易活动的顺利进行。但如因不可抗力或其他百纳芯城无法控制的原因使百纳芯城网站崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，百纳芯城不承担责任；任何用户向百纳芯城提供错误、不完整、不实信息等造成不能正常使用百纳芯城服务或遭受任何其他损失，概与百纳芯城无关</p>
                    </div>

                    <div class="deliver_title">八、其它</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">本协议在所有方面均受中华人民共和国法律管辖。本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行。您同意，在发生并购，本协议和所有纳入的协议可由百纳芯城自行酌情决定向第三方自动转让。标题仅为参考之用，在任何方面均不界定、限制、解释或描述该条的范围或限度。</p>
                    </div>

                </div>
                <div v-if="open == 6" class="">
                    <div class="help_title">免责声明</div>

                    <div class="deliver_title">1.关于本站</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">1.1 本站旨在为广大用户提供更多的信息，其服务是无偿的；本站不保证向用户提供的外部链接的准确性和完整性，该外部链接指向的不由本站实际控制的任何网页上的内容，本网站对其合法性亦概不负责，亦不承担任何法律责任，本站内所有内容亦不表明本网站之观点或意见。</p>
                        <p class="deliver_txt">1.2 任何单位或个人认为本站内容可能涉嫌侵犯其合法权益，应该及时向本站书面反馈，并提供身份证明、权属证明及详细情况证明，本站在收到上述文件后将会尽快移除相关内容。</p>
                        <p class="deliver_txt">1.3 由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果，本网站均得免责。</p>
                        <p class="deliver_txt">1.4 由于用户将个人密码告知他人或与他人共享注册账号，由此导致的任何个人资料泄露，本网站不负任何责任。</p>
                        <p class="deliver_txt">1.5 当政府司法机关依照法定程序要求本网站披露个人资料时，我们将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本网站均得免责。</p>
                        <p class="deliver_txt">1.6 凡以任何方式登录本网站或直接、间接使用本网站资料者，视为自愿接受本网站声明的约束。</p>
                    </div>

                    <div class="deliver_title">2.网站运行</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">2.1 本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本公司控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。</p>
                        <p class="deliver_txt">2.2 任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等，本网站不承担责任。</p>
                        
                    </div>

                    <div class="deliver_title">3.法律责任</div>
                    <div class="deliver_box">
                        <p class="deliver_txt">3.1 本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本公司控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本网站不负任何责任。</p>
                        <p class="deliver_txt">3.2 凡以任何方式登录本网站或直接、间接使用本网站资料者，视为自愿接受本网站声明的约束。</p>
                        <p class="deliver_txt">3.3 本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。</p>
                    </div>

                </div>
            </div>


        </div>

        <foot active="help" @get="get"></foot>
        <toolbar />
    </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";
import { useStore } from "vuex";
import { toRefs, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        store.commit("setCount");

        let state = reactive({
            userid: store.state.userId,
            open: route.query.id, 
            menu:[
                {
                    name:'使用帮助',
                    children:[
                        { id: 1, name:'购物流程' },
                        { id: 2, name:'发货与签收' },
                        { id: 3, name:'退换货说明' },
                        { id: 4, name:'配送方式' },
                    ]
                },
                {
                    name:'使用协议',
                    children:[
                        { id: 5, name:'使用协议' },
                    ]
                },
                {
                    name:'免责声明',
                    children:[
                        { id: 6, name:'免责声明' },
                    ]
                }
            ]
        });

        let change_name = (v) => {
            state.open = v.id
            router.replace({
                path: '/help',
                query: {
                    id: v.id
                }
            }) 
            window.scrollTo(100,0)
        };

        let get = (d) => {
            state.open = d.id
            window.scrollTo(100,0)
        }

        return {
            ...toRefs(state),
            change_name,
            get
        };
    },
    components: {
        topnav,
        topmenu,
        foot,
        toolbar
    },
};
</script>

<style lang="less" scoped>
.help {
    padding: 154px 0 440px;

        .help_wrap {
            padding: 20px 0;
        }

    .help_left {
        width: 200px;
        height: 816px;
        background: #FFFFFF;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        margin-right: 24px;

        .menu_title {
            height: 38px;
            background: #F4F4F4;
            border-bottom: 1px solid #E5E5E5;
            padding-left: 50px;
            line-height: 38px;
            font-size: 15px;
            font-weight: 600;
            color: #333333;
        }
        
        .son {
            padding-top: 20px;
        }

        .son_title {
            margin-bottom: 20px;
            padding-left: 50px;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            color: #666666;
            cursor: pointer;

        }
        
        .son_title.active {
            color: #1C46B7;
        }
    }

    .help_right {
        flex:1 ;
        background: #FFFFFF;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        padding: 34px 40px 86px;

        .help_title {
            font-size: 15px;
            font-weight: 600;
            line-height: 21px;
            color: #333333;
            padding-bottom: 7px;
            border-bottom: 1px solid #E9E9E9;
            margin-bottom: 23px;
        }

        .help_step {
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            color: #333333;
            margin-bottom: 7px;
            span {
                color: #1C46B7;
            }
        }

        .help_img {
            max-width: 788px;
            margin-bottom: 108px;
        }

        .deliver_title {
            font-size: 15px;
            font-weight: 600;
            line-height: 21px;
            color: #333;
            margin-bottom: 4px;
        }

        .deliver_txt {
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            color: #666;
        }

        .deliver_box {
            margin-bottom: 42px;
        }

        .blue {
            color: #1C46B7;
        }

    }

}
</style>
